@use 'libs/styles/chains';
@use 'libs/styles/layout';
@use 'libs/styles/variables';

.network-icon {
  @include chains.chain-colors;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  box-shadow: 0 7px 18px var(--shadow-color);

  &--name {
    @include layout.row;
    @include variables.paragraph;
    gap: 0;
    justify-content: flex-start;
    width: 100%;
    height: unset;
    border-radius: 1rem;
    padding: 0.125rem 0.25rem;
    font-weight: 500;
    box-shadow: none;
  }

  &--tiny {
    width: 22px;
    height: 22px;

    svg {
      width: 22px;
      height: 22px;
    }
  }
}
