@use 'libs/styles/variables';
@use 'libs/styles/utils';

.progress {
  position: relative;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  background-color: rgba(variables.$color-slate, 0.3);

  &--breakdown {
    height: 15px;

    .progress-part {
      height: 15px;

      &:not(:first-child) {
        border-left: 2px solid white;
      }
    }
  }
}

.progress-part {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 8px;
}
