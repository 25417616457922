@use 'libs/styles/variables';
@use 'libs/styles/layout';

.select-container {
  position: relative;
  display: flex;
  flex: 1;
}

.down-icon {
  color: variables.$color-slate;
  transition: transform 0.5s;

  .dropdown[data-state='open'] & {
    transform: rotateX(180deg);
  }
}

.dropdown {
  display: flex;
  flex: 1;
  border: 1px solid variables.$color-lighter;
  border-radius: variables.$border-radius-m;
  padding: 16px;
  background-color: transparent;
  box-shadow: variables.$box-shadow-medium;
  cursor: pointer;

  & > span {
    @include layout.row;
    flex: 1;
    justify-content: space-between;
  }
}

.remove-icon {
  position: absolute;
  top: 50%;
  right: 14px;
  width: 28px;
  height: 28px;
  padding: 0;
  transform: translateY(-50%);

  svg {
    min-height: 16px;
  }
}

.placeholder {
  @include variables.paragraph(true);
  padding: 0 16px;
  color: variables.$color-slate;
}

.list {
  max-height: 30vh;
  border: 1px solid variables.$color-lighter;
  border-radius: variables.$border-radius-m;
  padding: 0 16px;
  overflow: hidden auto;
  background-color: variables.$color-white;
  box-shadow: variables.$box-shadow-medium;

  & > *:not(:last-of-type) {
    border-bottom: 1px solid variables.$color-lighter;
  }
}
