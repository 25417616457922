@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';
@use 'sass:color';

.type-button {
  @include utils.increase-specificity {
    display: grid;
    grid-template-columns: 3rem 1fr 2rem;
    justify-content: flex-start;
    height: max-content;
    border: 1px solid var(--button-color-half);
    border-radius: 1.5rem;
    padding: 1rem 1rem 1rem 1.25rem;
    padding-left: 1rem;
    overflow: hidden;
    background: linear-gradient(180deg, #fff 0%, #fefeff 75.3%, #f9fdff 100%);
    box-shadow: var(--button-box-shadow-light);
    transition: variables.$transition-l;
  }

  &--small {
    display: flex;
    grid-template-columns: unset;
    justify-content: space-between;
  }

  &:not(.type-button--small) > div:first-child {
    border: 1px solid var(--button-color-half);
    color: var(--button-color);
    transition: variables.$transition-m;
  }

  & > div:last-child > svg > path {
    stroke: var(--button-color);
  }

  p {
    text-align: left;
    text-wrap: wrap;
    color: var(--button-color);
  }

  h4 {
    transition: variables.$transition-m;
  }

  &:hover {
    box-shadow: var(--button-box-shadow-medium);

    @include utils.increase-specificity {
      border-color: var(--button-color);
    }

    &:not(.type-button--small) > div:first-child {
      box-shadow: var(--button-box-shadow-medium);
      transform: translateY(-0.25rem);
    }

    & > div:last-child > svg {
      opacity: 0.75;
      animation: slide 0.6s ease;
    }

    h4 {
      color: var(--button-color);
    }
  }

  @media (width <= 40rem) {
    width: 100%;
  }

  & > div:last-child > svg {
    opacity: 0.25;
    transition: variables.$transition-s;
  }

  &--red {
    --button-color: #{variables.$color-error};
    --button-color-half: #{rgba(variables.$color-error, 0.5)};
    --button-box-shadow-light: 0 10px 30px 0 #{rgba(
        color.mix(variables.$color-error, variables.$color-dark-light, $weight: 85%),
        0.05
      )};
    --button-box-shadow-medium: 0 10px 30px 0 #{rgba(
        color.mix(variables.$color-error, variables.$color-dark-light, $weight: 85%),
        0.1
      )};
  }

  &--purple {
    --button-color: #{variables.$color-purple};
    --button-color-half: #{rgba(variables.$color-purple, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light-purple};
    --button-box-shadow-medium: #{variables.$box-shadow-medium-purple};
  }

  &--pink {
    --button-color: #{variables.$color-pink};
    --button-color-half: #{rgba(variables.$color-pink, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light-purple};
    --button-box-shadow-medium: #{variables.$box-shadow-medium-purple};
  }

  &--blue {
    --button-color: #{variables.$color-dark-blue};
    --button-color-half: #{rgba(variables.$color-dark-blue, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light-blue};
    --button-box-shadow-medium: #{variables.$box-shadow-medium-blue};
  }

  &--brand-2 {
    --button-color: #{variables.$color-brand-2};
    --button-color-half: #{rgba(variables.$color-brand-2, 0.5)};
    --button-box-shadow-light: 0 10px 30px 0 #{rgba(
        color.mix(variables.$color-brand-2, variables.$color-dark-light, $weight: 85%),
        0.05
      )};
    --button-box-shadow-medium: 0 10px 30px 0 #{rgba(
        color.mix(variables.$color-brand-2, variables.$color-dark-light, $weight: 85%),
        0.1
      )};
  }

  &--black {
    --button-color: #{variables.$color-dark};
    --button-color-half: #{rgba(variables.$color-dark, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light};
    --button-box-shadow-medium: #{variables.$box-shadow-medium};
  }

  &--fund {
    --button-color: #{variables.$color-fund};
    --button-color-half: #{rgba(variables.$color-fund, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light-orange};
    --button-box-shadow-medium: #{variables.$box-shadow-medium-orange};
  }

  &--org {
    --button-color: #{variables.$color-org};
    --button-color-half: #{rgba(variables.$color-org, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light-blue};
    --button-box-shadow-medium: #{variables.$box-shadow-medium-blue};
  }

  &--green {
    --button-color: #{variables.$color-dark-green};
    --button-color-half: #{rgba(variables.$color-dark-green, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light-dark-green};
    --button-box-shadow-medium: #{variables.$box-shadow-medium-dark-green};
  }

  &--violet {
    --button-color: #{variables.$color-org-2};
    --button-color-half: #{rgba(variables.$color-org-2, 0.5)};
    --button-box-shadow-light: #{variables.$box-shadow-light-purple};
    --button-box-shadow-medium: #{variables.$box-shadow-medium-purple};
  }

  &:not(.type-button--small) &__icon {
    display: flex;
    flex: 0 0 50px;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }

  &__text {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.125rem;
    align-items: flex-start;

    & > h4 {
      @include variables.header(3);
      font-weight: 500;
      line-height: 125%;
      color: variables.$color-disabled;

      @media (width <= 40rem) {
        white-space: normal;
        text-align: left;
      }
    }

    & > p {
      @include variables.label;
      white-space: normal;
      color: var(--button-color);
    }
  }

  & > div:last-child {
    flex: 0 0 32px;
    overflow: hidden;
  }

  &__spinny {
    & > *:nth-child(1) > span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 52px;
      border-radius: 26px;
    }

    &:hover > *:nth-child(1) > span > svg {
      animation: variables.$spin-animation;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  49% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}
