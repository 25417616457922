@use 'libs/styles/variables';

.fund-card {
  @include variables.card-hover;
  text-align: left;
  background-color: variables.$color-white;

  header {
    margin-bottom: 1.5rem;

    h3 {
      @include variables.header(3);
      margin-bottom: 0;
    }

    span {
      @include variables.label;
      color: variables.$color-fund;
    }
  }

  &__avatar {
    color: variables.$color-fund;
  }

  & > p {
    @include variables.paragraph;
  }

  footer {
    display: flex;
    justify-content: space-between;

    span {
      @include variables.label;
    }
  }

  &--compact {
    height: 18rem;

    article {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    header {
      margin: 0;

      h3 {
        overflow: hidden;
        font-size: 1.25rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      span {
        color: variables.$color-light;
      }
    }

    footer {
      strong {
        @include variables.paragraph($big: true);
      }
    }
  }
}
