@use 'libs/styles/variables';
@use 'libs/styles/layout';

.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  height: 5.25rem;
  border-top: 1px solid rgba(variables.$color-purple, 0.25);
  border-bottom: 1px solid rgba(variables.$color-purple, 0.25);
  background-color: rgba(variables.$color-white, 0.85);
  backdrop-filter: blur(20px);

  @media (max-width: variables.$device-md) {
    height: 4.5rem;
  }

  &__inner {
    @include layout.max-width;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-right: 116px;
    padding-left: 116px;

    @media (max-width: variables.$device-md) {
      padding: 20px 24px 0;
    }
  }

  .navigation {
    @include layout.row;
    gap: 12px;

    &__link {
      @include variables.paragraph;
      display: inline-flex;
      gap: 0.25rem;
      align-items: center;
      padding: 12px 10px;
      font-weight: 500;
      line-height: revert;
      white-space: nowrap;
      text-decoration: none;
      color: variables.$color-disabled;
      transition: variables.$transition-s;

      svg {
        width: 24px;
        height: 24px;

        path {
          fill: currentcolor;
        }
      }

      &:hover {
        &:first-child {
          color: variables.$color-fund;
        }

        &:nth-child(2) {
          color: variables.$color-purple;
        }

        &:nth-child(3) {
          color: variables.$color-org;
        }
      }

      @media (max-width: variables.$device-md) {
        display: none;
      }
    }

    .app-button {
      gap: 0.4rem;
      margin-left: 12px;
      border: 1px solid rgba(variables.$color-purple, 0.25);
      padding-right: 10px;
      padding-left: 16px;
      transition: variables.$transition-s;

      &:hover {
        border-color: rgba(variables.$color-purple, 0.75);
        color: variables.$color-purple;
      }
    }
  }
}

.logo {
  flex: 1;
}
