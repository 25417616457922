@mixin increase-specificity($depth: 1) {
  $sel: '';

  @while $depth > 0 {
    $sel: $sel + ':nth-child(n)';
    $depth: $depth - 1;
  }

  &#{$sel} {
    @content;
  }
}
