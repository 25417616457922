@use 'libs/styles/variables';

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(365deg);
  }
}

.loader {
  display: inline-flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: variables.$color-purple;
  visibility: hidden;

  &--loading {
    visibility: visible;

    & > * {
      animation: spin 0.75s linear infinite;
    }
  }

  &--white {
    color: white;
  }
}
