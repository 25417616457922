@use 'libs/styles/variables';
@use 'libs/styles/layout';

.empty-state-block {
  @include layout.max-width(true);
  @include layout.row(1rem);
  justify-content: center;
  align-items: center;
  padding: 48px 0;

  & > * {
    flex: 0 0 50%;
  }

  & > div:first-child {
    @include layout.column(4px);
    flex: 0 0 26%;

    h2 {
      @include variables.title(2, false);
      margin-top: 12px;
      margin-left: 8px;
      font-weight: 700;
      font-family: 'GT Haptik', sans-serif;
      line-height: 1;
      text-align: left;
      color: variables.$color-dark-light;
    }

    p {
      @include variables.header(3);
      margin: 8px 0 24px 8px;
      text-align: left;
      color: variables.$color-purple;
    }

    button {
      height: 48px;
    }
  }

  .image {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 300px;

    img {
      object-fit: contain;
    }
  }

  &--reverse {
    flex-direction: row-reverse;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &--spread {
    justify-content: space-evenly;
    padding: 24px 0;

    & > div:first-child {
      flex: 0 0 40%;

      h2 {
        @include variables.title(3, false);
        margin-top: 0;
        font-weight: 700;
        font-family: 'GT Haptik', sans-serif;
        line-height: 1;
        color: variables.$color-dark-light;
      }

      p {
        @include variables.paragraph(true);
        margin-bottom: 20px;
      }
    }
  }

  &--vertical {
    @include layout.column(0);
    flex-direction: column-reverse;
    gap: 1.25rem;
    max-width: 400px;
    padding: 24px 8px;

    & > div:first-child {
      align-items: center;

      h2,
      p {
        text-align: center;
      }

      h2 {
        @include variables.title(3, false);
        font-weight: 700;
        font-family: 'GT Haptik', sans-serif;
        line-height: 1;
        color: variables.$color-dark-light;
      }

      p {
        @include variables.paragraph(true);
        margin-bottom: 20px;
      }
    }

    .image {
      flex: 0 0 250px;
      height: 250px;
    }
  }
}
