@use 'libs/styles/variables';

.button {
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 16;
  width: 48px;
  height: 48px;
  padding: 0;
  overflow: visible;
  transform: translateY(-75%);

  &::after {
    position: absolute;
    top: 20px;
    left: 50%;
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid rgba(variables.$color-purple, 0.5);
    border-top: none;
    border-right: none;
    transform: rotate(45deg) translateX(-50%);
    content: ' ';
  }

  &__next {
    right: 20px;
    left: unset;

    &::after {
      top: 26px;
      transform: rotate(-135deg) translateX(100%);
    }
  }

  &--outside {
    left: 5px;

    &.button__next {
      right: 5px;
      left: unset;
    }
  }

  @media (max-width: variables.$device-md) {
    display: none;
  }
}
