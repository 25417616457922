@use 'libs/styles/variables';

.status-badge {
  display: inline-block;
  width: variables.$space-s;
  height: variables.$space-s;
  border-radius: variables.$space-s;
  background-color: variables.$color-on;

  &--off {
    background-color: variables.$color-off;
  }

  &--error {
    background-color: variables.$color-error;
  }
}
