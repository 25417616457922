@use 'libs/styles/variables';
@use 'libs/styles/layout';

.social {
  position: relative;
  z-index: 1;
  align-self: center;
  width: fit-content;
  border: 1px solid variables.$color-lighter;
  border-radius: 1rem;
  padding: 0.5rem 0.75rem;
  background: white;

  @media (max-width: variables.$device-md) {
    flex-wrap: wrap;
    justify-content: flex-start;
    border: none;
    padding: 0;
    background: transparent;
  }

  &__section {
    @include layout.max-width;
    @include layout.row;
    position: relative;
    margin: 3rem 0 1rem;

    @media (max-width: variables.$device-md) {
      justify-content: flex-start;
      padding: 0;
    }

    &::before {
      @include layout.absolute-box;
      top: 50%;
      z-index: 0;
      height: 1px;
      background: variables.$color-lighter;
      transform: translateY(-50%);

      @media (max-width: variables.$device-md) {
        display: none;
      }
    }
  }

  a {
    @include layout.row;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: variables.$color-slate;
    transition: variables.$transition-s;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: white;

      @media (max-width: variables.$device-md) {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &:hover {
      background-color: variables.$color-purple;
    }
  }
}
