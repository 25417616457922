@use 'libs/styles/variables';

.tooltip {
  height: 100%;

  svg {
    width: 32px;
    height: 100%;
    color: variables.$color-org;
  }

  &__current-color {
    svg {
      color: currentcolor;
    }
  }
}
