@font-face {
  font-weight: 400;
  font-family: Graphik;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/Graphik/Graphik-Regular.woff');
}

@font-face {
  font-weight: 500;
  font-family: Graphik;
  font-style: normal;
  src: url('./fonts/Graphik/Graphik-Medium.woff');
  font-display: swap;
}

@font-face {
  font-weight: bold;
  font-family: Graphik;
  font-style: normal;
  src: url('./fonts/Graphik/Graphik-Semibold.woff');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: Signifier;
  font-style: normal;
  src: url('./fonts/Signifier/Signifier-Light.otf');
  font-display: swap;
}

@font-face {
  font-weight: normal;
  font-family: Signifier;
  font-style: normal;
  src: url('./fonts/Signifier/Signifier-Regular.otf');
  font-display: swap;
}

@font-face {
  font-weight: bold;
  font-family: Signifier;
  font-style: normal;
  src: url('./fonts/Signifier/Signifier-Bold.otf');
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: 'GT Haptik';
  font-style: normal;
  font-display: swap;
  src: url('./fonts/GT-Haptik/GT-Haptik-Regular.otf');
}

@font-face {
  font-weight: bold;
  font-family: 'GT Haptik';
  font-style: normal;
  src: url('./fonts/GT-Haptik/GT-Haptik-Bold.otf');
  font-display: swap;
}

@font-face {
  font-weight: bold;
  font-family: 'GT Maru Mono';
  font-style: normal;
  src: url('./fonts/GT-Maru/GT-Maru-Mono-Medium.ttf');
  font-display: swap;
}
