@use 'libs/styles/variables';

.accordion {
  display: grid;
  grid-template: 1fr auto / 1fr min-content;
  grid-template-areas:
    'outer-content toggle-icon'
    'inner-content inner-content';
  padding: 1.75rem 0;
}

.accordion-outer {
  display: flex;
  grid-area: outer-content;
  align-items: center;
}

.accordion-icon {
  display: flex;
  grid-area: toggle-icon;
  align-items: center;
  min-height: 2rem;

  & > svg {
    stroke: variables.$color-org;

    line {
      transition: opacity 0.5s;

      &.open {
        opacity: 0;
      }
    }
  }
}

.accordion-inner {
  grid-area: inner-content;
  width: 100%;
  overflow: hidden;
}
