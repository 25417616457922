@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';
@use 'sass:color';

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  width: 40rem;
  max-height: calc(100svh - 3rem);
  border: 1px solid variables.$color-lighter;
  border-radius: variables.$border-radius-l;
  padding: 1.5rem 1.2rem 0;
  background: variables.$color-white;
  box-shadow: variables.$box-shadow-medium;

  &__xl {
    width: 50rem;
    max-width: 50rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: calc(100svh - 8rem);
    padding: 0 1.5rem;

    & > hr {
      margin: 0.5rem;
      border: 0.8px solid rgba(variables.$color-lighter, 0.8);
    }

    @media (width <= 40rem) {
      justify-content: flex-start;
      max-height: calc(100svh - 2rem);
      padding: 0;
    }
  }

  & form {
    @include layout.column;
  }

  button[type='submit'] {
    align-self: center;
  }

  &--impactful {
    border: 1px solid rgba(variables.$color-purple, 0.5);
    background: variables.$color-near-white;
    box-shadow: variables.$box-shadow-medium-purple;
  }

  @media (width <= 40rem) {
    bottom: 0;
    justify-content: flex-start;
    min-width: unset;
    max-height: calc(100svh - 2rem);
    margin-top: auto;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    padding: 0 1rem;
    padding-bottom: 0.5rem;
    overflow: scroll;
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: 26px auto 26px;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 0.5rem 0 1rem;
  background-color: white;

  &-title {
    grid-column-start: 2;
    font-size: 1.125rem;
    font-weight: 400;

    /* Paragraph Big/Desktop */
    font-family: Graphik, sans-serif;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0;
    text-align: center;
    color: variables.$color-slate;
  }

  &-button {
    @include utils.increase-specificity(2) {
      $size: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: $size;
      height: $size;
      margin: 0;
      padding: 0;
      background: rgba(variables.$color-purple, 0.125);

      & > svg {
        width: 18px;
        height: 18px;
      }

      &:hover {
        background: rgba(variables.$color-purple, 0.125);
      }
    }
  }

  @media (width <= 40rem) {
    padding: 2rem 18px 1rem;
  }
}

.donation-progress {
  position: sticky;
  bottom: 0;
  z-index: 5;
  margin-top: 1.5rem;
  background-color: white;

  &::before {
    position: absolute;
    top: -2rem;
    left: 0;
    z-index: 1;
    display: inline-block;
    flex: 0 0 1rem;
    width: 100%;
    height: 2rem;
    background: linear-gradient(transparent, white 75%);
    content: ' ';
  }

  & > :first-child {
    min-height: 0.25rem;

    & > div,
    &::after {
      height: 0.25rem;
      border-radius: 0.375rem;
    }

    & > :first-child {
      background: variables.$color-purple;
    }
  }

  & > :last-child {
    justify-content: center;
    font-weight: 500;
  }

  @media (width <= 40rem) {
    padding-bottom: 1rem;
  }
}

.bottom-space {
  display: block;
  height: 1.5rem;
  content: ' ';
}
