@use 'libs/styles/variables';
@use 'libs/styles/utils';

.dashed-border {
  @include utils.increase-specificity(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    padding: 0;
    overflow: hidden;
  }

  &--loading {
    rect {
      animation: variables.$dash-animation--reverse;
    }
  }
}
