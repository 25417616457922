/* stylelint-disable scss/dollar-variable-pattern */
@use 'sass:math';

$MAX_WIDTH: 90rem;

@mixin row($gap: 8px, $inline: false) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-direction: row;
  gap: $gap;
  justify-content: center;
  align-items: center;
}

@mixin column($gap: 8px, $inline: false) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-direction: column;
  gap: $gap;
  justify-content: center;
  align-items: stretch;
}

@mixin multi-column($gap: 8px, $count: 2) {
  @include row($gap);

  $per-column-basis: math.div(100%, $count);
  $adjusted-gap: math.div($gap, $count) - $gap;
  flex-wrap: wrap;

  & > * {
    flex-basis: if(math.is-unitless($adjusted-gap), $per-column-basis, calc($per-column-basis + $adjusted-gap));
  }
}

@mixin absolute-box {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  content: ' ';
}

@mixin max-width($form: false, $padding: true) {
  width: 100%;

  @if $form {
    max-width: math.div($MAX_WIDTH, 1.5);
  } @else {
    max-width: $MAX_WIDTH;
  }
  margin-right: auto;
  margin-left: auto;

  @if $padding {
    padding-right: 32px;
    padding-left: 32px;
  }
}
