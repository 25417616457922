@use 'libs/styles/variables';
@use 'libs/styles/utils';

.modal {
  border-radius: variables.$border-radius-m;
  padding: variables.$space-l;
  text-align: center;
  color: variables.$color-dark-light;
  background-color: variables.$color-white;

  @media (max-width: variables.$device-md) {
    margin: 0 1rem;
  }

  &--sticky-header {
    padding-top: 0;
  }

  &--small {
    width: fit-content;
    min-width: 30rem;
  }
}

.branded-logo {
  path {
    fill: rgba(variables.$color-purple, 0.25);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  margin-bottom: variables.$space-m;

  svg {
    display: block;
  }

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    height: unset;
    margin-bottom: 0;
    padding-top: variables.$space-l;
    padding-bottom: variables.$space-m;
    background: white;

    @media (max-width: variables.$device-md) {
      margin: 0 -1.5rem 5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
}

.title {
  @include variables.header(3);
  margin: 0;
  font-weight: 500;
  line-height: 1;
  color: variables.$color-disabled;

  [class*='LogoIcon'] + & {
    margin: 2rem 0 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  @include utils.increase-specificity {
    $size: 32px;
    width: $size;
    height: $size;
    padding: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  &--show {
    visibility: visible !important;
  }

  &--back {
    border: 1px solid #efefef;
    background-color: variables.$color-white;

    &:hover {
      border: 1px solid #efefef;
    }
  }

  &--close {
    @include utils.increase-specificity {
      background-color: rgba(variables.$color-purple, 0.05);
      outline: none;

      & > svg {
        color: rgba(variables.$color-purple, 0.5);
      }

      &:hover {
        color: rgba(variables.$color-purple, 0.75);
        background-color: rgba(variables.$color-purple, 0.125);
        box-shadow: none;

        & > svg {
          color: variables.$color-purple;
        }
      }
    }
  }
}
