/* stylelint-disable scss/dollar-variable-pattern */
@use 'sass:math';
@use 'sass:color';
@use './layout' as layout;
@use './utils' as utils;

/* Limits */
$CONTENT_WIDTH: 51.5625rem;
$MAX_WIDTH: layout.$MAX_WIDTH;
$NAVBAR_HEIGHT: 5rem;
$FOOTER_HEIGHT: 15rem;
$SUPER_HEADER_HEIGHT: 3rem;
$HEADER_HEIGHT: 8rem;

/* Default section heights: */
$LINK_SECTION_HEIGHT: 10rem;

/* Device sizes */
$device-sm: 40rem;
$device-md: 71rem;
$device-lg: 87.5rem;

/* Spacing */
$space-s: 0.5rem;
$space-m: 1rem;
$space-l: 1.5rem;
$space-xl: 2rem;
$space-xxl: 4.5rem;
$space-xxxl: 7.5rem;

/* Font sizes
 * TODO: replace occurences in actual front-end with mixins
 */
$font-size-xs: 0.75rem;
$font-size-s: 0.875rem;
$font-size-ms: 0.95rem;
$font-size-m: 1.125rem;
$font-size-ml: 1.25rem;
$font-size-l: 1.5rem;
$font-size-xl: 2rem;
$font-size-xxl: 2.75rem;
$font-size-xxxl: 4.5rem;
$font-size-xxxxl: 6.5rem;

/* Font families */
$font-primary: 'GT Haptik', sans-serif;
$font-secondary: 'Graphik', sans-serif;

/* Colors */
$color-brand: #ea6b0e;
$color-brand-2: #ea4705;
$color-dark: #111213;
$color-white: #fff;
$color-fund: #ea6b0e;
$color-org: #53acde;
$color-org-2: #8478e0;
$color-base: #efefef;
$color-near-white: #f8fbff;
$color-light: #c4c4c4;
$color-lighter: #e0e4e9;
$color-on: #35dca0;
$color-off: #f49855;
$color-error: #f01414;
$color-dark-blue: #0083cf;
$color-disabled: #696f8c;
$color-dark-light: #393f49;
$color-slate: #adbacc;
$color-dark-slate: #969fad;
$color-gray: #5e6775;
$color-off-light: #f2f3f6;
$color-light-orange: #ffecdcc2;
$color-purple: #627eea;
$color-pink: #ff6fc5;
$color-purple-dark: #1d1db7;
$color-purple-very-dark: #2f3479;
$color-purple-gray: #afa6c9;
$color-purple-gray-dark: #5b4972;
$color-green: #3ad091;
$color-dark-green: #03814d;
$color-error-light: rgb(255, 185, 176);
$color-dark-red: #9e0142;
$color-dark-orange: #e05606;
$color-logo-blue: #0e243e;
$color-light-border: rgba($color-purple-gray, 0.5);
$color-border: $color-slate;
$color-sky: rgb(199, 210, 255);

/* Chain themes */
$color-ethereum: #2f3479;
$color-optimism: #ff0420;
$color-polygon: #8247e5;
$color-basechain: #2151f5;
$color-arbitrum: #12aaff;

/* Impact site variables */
$color-impact-background: #bccbff;
$color-impact-purple: #4c65bb;
$color-impact-scarlet: #c61a76;
$impact-dark-shadow: drop-shadow(0 19px 45px rgba(47, 67, 248, 38%));
$impact-big-dark-shadow: drop-shadow(0 67px 70px rgba(47, 67, 248, 32%));

/* Border radius */
$border-radius-s: 0.5rem;
$border-radius-m: 1.25rem;
$border-radius-l: 2.25rem;
$border-radius-xxl: 6.25rem;

/* Shadow */
$box-shadow-light: 0 4px 16px 0 #00000005;
$box-shadow-medium: 0 18px 43px rgb(75 51 142 / 6%);
$box-shadow-floating: 0 18px 43px rgb(75 51 142 / 14%);
$box-shadow-heavy: 0 4px 16px 0 $color-dark;
$box-shadow-light-orange: 0 10px 30px 0 rgba(color.mix($color-fund, $color-slate, $weight: 90%), 0.05);
$box-shadow-medium-orange: 0 18px 26px rgb(234 107 14 / 12%);
$box-shadow-medium-red: 0 18px 26px rgba(244, 39, 39, 12%);
$box-shadow-light-blue: 0 10px 30px 0 rgba(color.mix($color-org, $color-dark-light, $weight: 85%), 0.05);
$box-shadow-medium-blue: 0 18px 26px rgb(83 172 222 / 12%);
$box-shadow-light-purple: 0 10px 30px 0 rgba($color-purple, 0.05);
$box-shadow-medium-purple: 0 10px 30px 0 rgba($color-purple, 0.1);
$box-shadow-heavy-purple: 0 20px 45px 0 rgba($color-purple, 0.35);
$box-shadow-light-dark-green: 0 10px 30px 0 rgba($color-dark-green, 0.05);
$box-shadow-medium-dark-green: 0 10px 30px 0 rgba($color-dark-green, 0.1);
$screenshot-shadow:
  rgb(33 51 109 / 32%) 0 60px 123px -25px,
  rgb(5 51 91 / 8%) 0 35px 75px -35px;
$screenshot-shadow-filter: drop-shadow(0 60px 123px -25px rgb(33 51 109 / 32%)),
  drop-shadow(0 35px 75px -35px rgb(5 51 91 / 8%));
$token-shadow: drop-shadow(0 25px 25px rgba(79, 39, 171, 30%));
$tiny-shadow-filter: drop-shadow(0 4px 9px rgba(99, 86, 219, 18%));

// Transition time
$transition-s: 0.2s;
$transition-m: 0.5s;
$transition-l: 0.8s;

/* Animations */
$slide-animation: slide 5s linear infinite;

@keyframes slide {
  0% {
    left: -100%;
  }

  100% {
    // left: 0;
  }
}

$dash-animation: dash 100s linear infinite;
$draw-animation: draw 2.5s ease-in infinite;
$eye-animation: eye 2.5s ease-in-out infinite;
$draw-appear-animation: draw-appear 2.5s ease-in infinite;

@keyframes eye {
  0% {
    transform: none;
  }

  50% {
    transform: translateX(7px);
  }

  100% {
    transform: none;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 1000;
  }
}

@keyframes draw {
  85% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-appear {
  65% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

$dash-animation--reverse: dash-reverse 100s linear infinite;

@keyframes dash-reverse {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -1000;
  }
}

$spin-animation: spin 2s cubic-bezier(0.47, -0.01, 0.13, 1) forwards;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-36deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

$rotate-animation: rotate 26s linear forwards infinite;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

$float-animation: floating 4s ease-in-out infinite;
$medium-float-animation: medium-floating 5s ease-in-out infinite;
$big-float-animation: big-floating 8s ease-in-out infinite;

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes medium-floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes big-floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Font mixins */
@mixin title($level: 1, $sans-serif: true) {
  font-weight: 600;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 105%;
  letter-spacing: -0.05rem;
  color: $color-dark-light;

  @if $sans-serif {
    font-weight: 600;
    font-family: 'GT Haptik', Arial, Helvetica, sans-serif;
  }

  /* @if $sans-serif == false {
    font-size: 1.85rem;
    line-height: 115%;
    font-weight: 400;
    font-family: Signifier, Georgia, "Times New Roman", Times, serif;
    letter-spacing: initial;
  } */

  @if $level == 1 {
    font-size: 3rem;

    @media (max-width: $device-md) {
      font-size: 2.75rem;
    }
  } @else if $level == 2 {
    font-size: 2.625rem;
    font-weight: 600;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-style: normal;
    line-height: 105%;
    letter-spacing: -0.05rem;

    @media (max-width: $device-md) {
      font-size: 2.325rem;
    }
  } @else if $level == 3 {
    font-size: 2rem;
    font-weight: 500;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    line-height: 115%;

    @media (max-width: $device-md) {
      font-size: 2.325rem;
    }
  } @else {
    font-size: 1.65rem;
    font-weight: 500;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-style: normal;
    line-height: 115%;
  }

  @media (max-width: $device-md) {
    font-size: 1.5rem;
  }
}

@mixin header($level: 1) {
  font-weight: 500;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 112.5%;
  letter-spacing: normal;

  @if $level == 1 {
    font-size: 2.25rem;

    @media (max-width: $device-md) {
      font-size: 1.85rem;
    }
  } @else if $level == 2 {
    font-size: 1.5rem;
    line-height: 125%;

    @media (max-width: $device-md) {
      font-size: 1.25rem;
    }
  } @else if $level == 3 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 125%;

    @media (max-width: $device-md) {
      font-size: 1rem;
    }
  } @else {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 145%;

    @media (max-width: $device-md) {
      font-size: 0.825rem;
    }
  }
}

@mixin paragraph($big: false) {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.01rem;

  @media (max-width: $device-md) {
    font-size: 0.75rem;
  }

  @if $big {
    font-size: 1rem;
    line-height: 135%;

    @media (max-width: $device-md) {
      font-size: 0.85rem;
    }
  }
}

@mixin label {
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.02rem;
  color: $color-slate;
}

@mixin text-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }
}

@mixin button-section-height {
  max-height: $LINK_SECTION_HEIGHT;

  @media (max-width: $device-md) {
    max-height: unset;
  }
}

@mixin icon-grid {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 0 50px;

  @media (max-width: $device-sm) {
    grid-template: auto / 1fr;
    gap: 8px;
    padding: 12px;
  }

  & > div {
    display: inline-flex;
    align-items: center;
    max-width: 400px;

    svg {
      flex: 0 0 45px;
      width: 45px;
      height: 45px;
      margin: 22px 30px;
      object-fit: contain;

      @media (max-width: $device-md) {
        margin: 12px 36px;
      }
    }

    p {
      @include paragraph($big: true);
      max-width: 300px;
      font-weight: 400;
      text-align: left;
      color: $color-dark-light;

      a {
        text-decoration: none;
        color: $color-purple;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

@mixin claim-form {
  @include layout.column;

  h3,
  .agree {
    margin-top: 32px;
    border-top: 1px solid rgba($color-purple, 0.1);
    padding-top: 32px;

    & > div:last-child {
      text-align: center;
    }

    a {
      @include text-link;
      @include paragraph;
      margin-left: 4px;
      color: $color-org;
    }
  }

  h3:first-of-type {
    margin-top: 0;
  }

  .split-row {
    @include layout.multi-column(32px);
    align-items: flex-start;
  }

  .split-row-3 {
    @include layout.multi-column(32px, 3);
    align-items: flex-start;
  }

  .submit-button {
    align-self: center;
    margin-top: 18px;
    margin-bottom: 18px;
    box-shadow: $box-shadow-medium-blue;
  }

  .agree {
    display: flex;
    justify-content: center;
    align-self: stretch;

    * {
      cursor: pointer;
    }
  }

  p {
    max-width: 30rem;
    margin-bottom: 12px;
    color: $color-disabled;
  }
}

@mixin receipt-list {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid $color-lighter;
  border-radius: 24px;
  padding: 18px 24px;
  text-align: start;
  list-style: none;
  background-color: white;

  & > li {
    @include paragraph;
    display: flex;
    padding: 8px 0 5px;
    color: $color-slate;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($color-purple, 0.1);
    }

    & > span {
      display: block;
      margin-left: auto;
      text-align: end;

      &:first-child {
        color: $color-disabled;
      }
    }
  }
}

@mixin cta-hero {
  @include rainbow-gradient-background('bottom', false, 300px);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  margin-top: 64px;
  padding-bottom: 160px;

  & > div:nth-child(2) {
    @include screenshot-image;

    $w: min(60vw, calc(#{$MAX_WIDTH} * 0.6));
    position: relative;
    z-index: 0;
    width: $w;
    height: calc($w / 2);
    margin-top: 48px;
    aspect-ratio: 1 / 2;

    &::after {
      position: absolute;
      right: -100px;
      bottom: -100px;
      left: -100px;
      z-index: 0;
      display: inline-block;
      height: 200px;
      background: linear-gradient(rgb(255 255 255 / 0%), white 50%);
      content: ' ';
    }

    img {
      object-fit: cover;
      object-position: top;
    }

    @media (max-width: $device-sm) {
      width: 80vw;
      height: 40vw;
      overflow: hidden;
    }
  }

  & > div:nth-child(3) {
    position: relative;
    display: flex;
    gap: 48px;
    justify-content: center;
    align-items: center;
    margin-top: 56px;

    @media (max-width: $device-md) {
      flex-direction: column;
      padding: 0 24px 72px;
    }

    h1 {
      @include title(3);
      position: relative;
      flex: 0 1 500px;
      margin-top: 0;
      border-right: 1px solid $color-light-border;
      padding-right: 48px;
      text-align: right;
      color: $color-dark-light;

      @media (max-width: $device-md) {
        flex: 1;
        border-right: none;
        border-bottom: 1px solid $color-light-border;
        padding-right: 0;
        padding-bottom: 32px;
        text-align: center;
      }
    }

    a {
      height: fit-content;
      margin-right: 18px;

      path {
        fill: $color-brand;
      }

      @media (max-width: $device-md) {
        margin-right: 0;
      }
    }
  }
}

@mixin large-info-card($color: $color-fund, $shadow: $box-shadow-light-orange, $align: 'right', $type: 'fund') {
  position: relative;
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  width: calc(100% - 6rem);
  height: 90%;
  border-radius: 36px;
  padding: 38px 32px 38px 42px;
  transition: $transition-m;

  @if $type == 'fund' {
    padding-top: 48px;
  }

  &__info {
    display: grid;
    flex: 1;
    grid-template: 1fr / 2fr auto auto;
    grid-template-areas: 'title impact' 'description description' 'bottom bottom';
    gap: 16px;
    place-items: center center;

    @media (max-width: $device-md) {
      padding-top: 8px;
    }
  }

  &::after {
    @include paragraph;
    position: absolute;
    top: -18px;
    border-radius: $border-radius-xxl;
    padding: 6px 14px;
    font-weight: 500;
    color: $color-white;
    background-color: $color;
    box-shadow: $shadow;
    content: 'Featured';
    #{$align}: 32px;
  }

  .avatar {
    $d: 120px;
    flex: 0 0 $d;
    width: $d;
    height: $d;

    svg {
      width: 50%;
      height: 50%;
    }

    &-org {
      $height: 120px;
      $padding: 0.35rem;
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      min-width: $height;
      height: $height;
      border: 1px solid $color-lighter;
      border-radius: 0.75rem;
      padding: $padding;
      overflow: hidden;
      background: $color-white;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 2%);

      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        border-radius: 0.5rem;
        overflow: hidden;
        background: transparent;
      }

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      svg {
        width: 50%;
        height: 50%;
      }

      span > img,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  em {
    font-style: normal;
    color: $color;
  }

  &__icon {
    grid-area: icon;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 1 / 1;
  }

  &__title {
    @include header(1);
    grid-area: title;
    justify-self: left;
    max-width: 100%;
    overflow: hidden;
    font-size: 1.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-dark-light;

    @if $type == 'fund' {
      margin-bottom: 0;
    } @else {
      margin-top: 8px;

      // align-self: flex-end;
    }

    & > p {
      @include paragraph;
      margin-top: 2px;
      color: $color-disabled;
    }

    @media (max-width: $device-md) {
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      margin-top: 0;
      font-size: 1.25rem;
      white-space: pre-wrap;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &__impact {
    @include header(1);
    grid-area: impact;
    justify-self: right;
    font-size: 1.75rem;
    text-align: end;
    color: $color;

    & > p {
      @include paragraph;
      margin-top: 0;
      color: $color-disabled;
    }

    @if $type == 'fund' {
      @media screen and (max-width: $device-md) {
        position: absolute;
        top: 32px;
        right: 24px;
      }
    }
  }

  &__description {
    @include paragraph(true);
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    flex: 1;
    grid-area: description;
    justify-self: left;
    max-width: 100%;
    min-height: 3rem;
    max-height: 6rem;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-disabled;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  &__stats {
    grid-area: bottom;
    justify-self: stretch;
    color: $color;

    span {
      @include label;
    }
  }

  &__extra {
    display: flex;
    grid-area: bottom;
    justify-self: stretch;
    border-top: 1px solid $color-lighter;
    padding-top: 16px;
    color: $color;

    span {
      @include label;

      &:last-child {
        margin-left: auto;
        color: $color;
      }
    }
  }

  &:hover {
    border-color: rgba($color, 0.5);

    .avatar {
      border-color: rgba($color, 0.5);
      box-shadow: $shadow;
      transform: translateY(-12px);
    }
  }

  @media (max-width: $device-md) {
    @include layout.column;
    width: calc(100% - 40px);
    padding: 1.5rem;
  }
}

@mixin activity-item {
  @include layout.row(0.35rem);
  width: 100%;

  &__description {
    @include layout.row(0.35rem, true);
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;

    & > span {
      @include layout.row(4px);
      align-items: center;
    }

    em {
      font-weight: 500;
      font-style: normal;
    }

    img {
      object-position: center left;
    }
  }

  & > div {
    @include layout.row(0.35rem, true);
    justify-content: flex-end;
  }
}

$rainbow-l-to-r: linear-gradient(
  270deg,
  #62c3ff 0%,
  #96d2ff 25.52%,
  #cbdaf2 43.62%,
  #ead2de 50.42%,
  #ffc2cb 59.64%,
  #ffc2cb 75%,
  #f8d2be 100%
);
$rainbow-r-to-l: linear-gradient(
  90deg,
  #62c3ff 0%,
  #96d2ff 25.52%,
  #cbdaf2 43.62%,
  #ead2de 50.42%,
  #ffc2cb 59.64%,
  #ffc2cb 75%,
  #f8d2be 100%
);

@mixin rainbow-gradient-background($position: 'top', $flip: false, $height: 160px) {
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 1px;

  @if $flip {
    background-image: if($position == 'top', $rainbow-r-to-l, $rainbow-l-to-r);
  } @else {
    background-image: if($position == 'top', $rainbow-l-to-r, $rainbow-r-to-l);
  }

  @if $position == 'bottom' {
    background-position: bottom;
  }

  &::after {
    position: absolute;
    right: 0;
    left: 0;
    z-index: -1;
    display: inline-block;
    width: 100%;
    height: $height;
    background: radial-gradient(111.25% 87.41% at 10.42% 5.11%, #fff5f1 3.54%, rgba(249, 253, 255, 0%) 100%),
      radial-gradient(
        32.09% 66.45% at 32.95% 0%,
        #f7f1ff 0%,
        rgba(247, 243, 255, 73%) 47.57%,
        rgba(249, 253, 255, 0%) 100%
      ),
      radial-gradient(83.32% 83.32% at 100% 0%, #e7f6ff 0%, rgba(249, 253, 255, 0%) 100%),
      radial-gradient(57.23% 77.08% at 32.64% 0%, #fff5f7 50.46%, rgba(255, 249, 249, 0%) 88.81%);
    content: ' ';

    @if $position == 'bottom' {
      bottom: 0;
      transform: scaleY(-100%) scaleX(-100%);
    } @else {
      top: 0;
    }

    @if $flip {
      @if $position == 'bottom' {
        transform: scaleY(-100%);
      } @else {
        transform: scaleX(-100%);
      }
    }
  }
}

@mixin globalize($width: 1400px, $top: 0, $position: 'left', $color: 'orange') {
  position: relative;
  padding-bottom: 72px;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: inline-block;
    width: 100%;
    height: 25%;
    background: linear-gradient(0deg, white, rgba(255, 255, 255, 0%));

    @if $position == 'left' {
      transform: translateX(-50%);
    } @else {
      transform: translateX(50%);
    }
    content: ' ';
  }

  &::after {
    position: absolute;
    top: 0;
    z-index: -2;
    display: inline-block;
    width: 100%;
    max-width: $width;
    height: 100%;
    background: url('/images/backgrounds/big-globe.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    opacity: 0.075;
    content: ' ';

    @if $color == 'blue' {
      filter: hue-rotate(180deg);
    }

    @if $position == 'left' {
      right: 55%;
      background-position-y: #{-12.5% - $top};
    } @else {
      left: 55%;
      background-position-y: #{-12.5% - $top};
      transform: scaleX(-1);
    }
  }
}

@mixin rainbow-gradient-border($position: 'top', $flip: false) {
  border-#{$position}: 1px solid;
  border-image-source: if($flip, $rainbow-r-to-l, $rainbow-l-to-r);
  border-image-slice: 1;
}

@mixin side-fade-gradients(
  $width: 50px,
  $light-color: rgba(255, 255, 255, 1),
  $dark-color: rgba(255, 255, 255, 0),
  $offset: 90%
) {
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    width: $width;
    background-image: linear-gradient(to left, $dark-color, $light-color $offset);
    content: '';
    pointer-events: none;

    @media (max-width: $device-md) {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    width: $width;
    background-image: linear-gradient(to right, $dark-color, $light-color $offset);
    content: '';
    pointer-events: none;

    @media (max-width: $device-md) {
      display: none;
    }
  }
}

@mixin screenshot-image {
  border: 10px solid rgba($color-purple, 0.1);
  border-radius: 24px;
  box-shadow: $box-shadow-medium;

  img {
    border-radius: 14px;
  }
}

@mixin info-badge($content: 'Coming Soon', $absolute: false, $condition: 'after', $color: $color-org) {
  display: flex;
  align-items: center;

  @if $absolute == true {
    position: relative;
  }

  &::#{$condition} {
    @include label;
    flex: 0;
    border-radius: 55px;
    padding: 5px 10px;
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    line-height: 15px;
    white-space: nowrap;
    color: $color-white;
    background-color: $color;
    box-shadow: 0 4px 8px rgba($color, 0.22);
    content: $content;

    @if $absolute == true {
      position: absolute;
    }
  }
}

@mixin card-list {
  @include layout.column(0);
  padding-bottom: 8px;

  & > ul {
    width: 100%;
    list-style: none;

    & > li {
      padding: 16px 0;

      &:not(:first-child) {
        border-top: 1px solid $color-lighter;
      }

      em {
        font-weight: 500;
        font-style: normal;
      }
    }
  }

  .title {
    @include layout.row(0);
    justify-content: space-between;
    border-bottom: 1px solid $color-lighter;
    padding-bottom: 16px;

    & > h2 {
      @include header(2);
      color: $color-disabled;
    }
  }

  &--no-data {
    @include paragraph($big: true);
    text-align: center;
  }
}

/* Standard card properties */
$card-content-padding: 0 0.45rem;

@mixin card-footer {
  @include label;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  border-top: 1px solid $color-lighter;
  padding: $card-content-padding;
  padding-top: 0.65rem;
  transition: $transition-m;

  em {
    font-style: normal;
    color: $color-fund;
  }
}

@mixin card-hover($spin: true) {
  transition: $transition-m;

  &:hover {
    box-shadow: $box-shadow-medium;
    transform: translateY(-12px);

    /* TODO: Remove (https://www.w3schools.com/cssref/sel_attr_contain.asp) */

    [class*='avatar']:not([class*='avatar-container']),
    :not([class*='Portfolio']) [class*='chain-icon'] {
      transform: translateY(-7px);

      @if $spin {
        :not([class*='network-icon']) svg {
          animation: $spin-animation;
        }
      } @else {
        box-shadow: $box-shadow-medium;
      }
    }
  }
}

@mixin card-row-button {
  display: flex;
  gap: 1.25rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: 16px;
  padding: $space-m $space-l;
  letter-spacing: 0.02rem;
  color: $color-dark-light;
  box-shadow: $box-shadow-light;
  cursor: pointer;
  transition: $transition-m;

  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    border-color: $color-fund;
    background-color: rgba($color-fund, 0.05);
    box-shadow: $box-shadow-medium;
    transform: translateY(-4px);
  }
}

@mixin rainbow-number {
  font-size: 4rem;
  font-weight: bold;
  font-family: $font-secondary;
  text-shadow: 0 5px 10px #c1bbd134;
  background: linear-gradient(rgb(207 207 207 / 8%), rgb(212 212 212 / 8%)),
    linear-gradient(150deg, #53acde 5%, #7164e2 40%, #eb4a4a 55%, #ea6b0e 85%);
  background-clip: text;
  animation: shine 5s ease-in-out infinite alternate;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  @media (min-width: $device-md) {
    font-size: $font-size-xxxl;
  }

  @keyframes shine {
    @for $i from 0 through 100 {
      $percent: $i + 0%;
      $quarter-percent: math.div($i, 2);
      #{$percent} {
        background: linear-gradient(rgb(207 207 207 / 8%), rgb(212 212 212 / 8%)),
          linear-gradient(
            150deg,
            rgb(83 171 222 / 100%) #{$quarter-percent - 35%},
            rgb(123 112 228) #{$quarter-percent + 15%},
            rgb(235 74 74) #{$quarter-percent + 35%},
            rgb(234 106 14 / 100%) #{$quarter-percent + 55%}
          );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
    }
  }
}

@mixin list-table($header: 'default') {
  @include label;
  width: 100%;
  text-align: left;

  th {
    font-weight: normal;
    text-transform: uppercase;

    @if $header == 'camelCase' {
      padding-bottom: 0.25rem;
      font-size: $font-size-ms;
      font-weight: bold;
      text-transform: capitalize;
      color: $color-slate;
      opacity: 0.75;
    }
  }

  td {
    padding: $space-s 0;
    color: $color-dark;

    &:not(:last-child) {
      padding-right: 1rem;
    }

    span {
      display: inline-flex;
      gap: $space-s;
      align-items: center;
    }
  }
}
