@use 'libs/styles/variables';

.styled-table {
  @include variables.list-table;
  width: 100%;
  border-spacing: 0;

  tbody {
    tr {
      &:not(:last-child) {
        margin-bottom: variables.$space-l;
      }

      &:nth-child(2n + 1) {
        background-color: variables.$color-near-white;
      }

      td,
      th {
        margin: 0;
        padding: variables.$space-m;

        &:first-child {
          text-align: left;
        }
      }

      th {
        position: sticky;
        top: 0;
        border-bottom: 1px solid variables.$color-light;
        padding-top: variables.$NAVBAR_HEIGHT;
        font-size: variables.$font-size-s;
        font-weight: bold;
        color: variables.$color-dark-light;
        background-color: white;

        &::after {
          position: absolute;
          right: 0;
          bottom: 12px;
          display: inline-block;
          width: 2px;
          height: 24px;
          background: variables.$color-light;
          opacity: 0.65;
          content: ' ';
        }
      }

      td {
        text-align: right;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          text-align: left;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        a {
          text-decoration: underline;
          color: variables.$color-dark-blue;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &--compact {
    tr {
      &:not(:last-child) {
        margin-bottom: variables.$space-s;
      }

      td {
        @include variables.label;
      }
    }
  }
}
