@use 'libs/styles/variables';

.progress {
  position: relative;
  min-height: 8px;
  border-radius: 4px;
  overflow: hidden;

  &-inner,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 8px;
    border-radius: 4px;
    background-color: currentcolor;
  }

  &::after {
    width: 100%;
    opacity: 0.1;
    content: ' ';
  }

  &-inner {
    opacity: 0.6;
    content: ' ';
  }
}

.labels {
  @include variables.label;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.35rem;
  padding: 0 0.25rem;
  text-transform: none;

  span,
  em {
    font-weight: 300;
  }

  em {
    font-style: normal;
    color: variables.$color-org-2;
  }

  & > :last-child {
    text-align: right;
  }
}
